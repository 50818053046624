@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.order-table-head {
  border-bottom: none;
  cursor: pointer;
  position: relative;

  &:hover {
    .order-table-head__cell {
      background-color: $color-black-2;
    }
  }
}

.order-table-head__cell {
  @include textSemiBold-16();
  background-color: $color-black-0;
  line-height: 32px;
  padding: 11px 0;
  text-align: left;
  transition: background-color $transition-time;
}

.order-table-head__cell_first {
  grid-column: 1 / 5;
  padding-left: 24px;
  padding-right: 24px;
}

.order-table-head__cell_last {
  grid-column: 6 / 7;
  padding-right: 24px;
}

.order-table-head__warning-icon {
  color: $color-red;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity $transition-time-icon-appearance;
}

.order-table-head__warning-icon_showed {
  opacity: 1;
}

.order-table-head__warning-icon_disappearance {
  opacity: 0;
  transition: opacity $transition-time-icon-disappearance;
}

.order-table-head__order-information {
  @include textRegular-14();
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  padding-left: 6px;
}

.order-table-head__icon-wrapper {
  background-color: $color-black-2;
  border-radius: 50%;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
  padding: 6px;
  transition: all $transition-time;
  width: 32px;
}

.order-table-head__icon {
  color: $color-text-ghost;
  transition: all $transition-time-table;
}
