@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.input__placeholder {
  @include textRegular-14-light();
  left: 12px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
