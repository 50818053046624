@import 'src/scss/variables';

.main-page-layout {
  display: grid;
  grid-template-areas:
    'navbar top-panel'
    'navbar main';
  grid-template-columns: $navbar-width 1fr;
  grid-template-rows: $header-height 1fr;
  height: 100vh;
}
