@import 'src/scss/variables';
@import 'src/scss/mixins/text';
@import 'src/scss/mixins/dropdown';

.multi-select {
  position: relative;
  width: 100%;
}

.multi-select__button {
  align-items: center;
  background: none;
  border: 1px solid $color-border-basic;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 20px;
  height: 36px;
  margin: 0;
  overflow: hidden;
  outline: none;
  padding: 6px 12px;
  transition: border-color $transition-time ease-in-out, box-shadow $transition-time ease-in-out;
  width: 100%;

  &:hover:not(:disabled):not(:focus) {
    border-color: $color-border-dark;
  }

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px $color-input-outline;
  }
}

.multi-select__arrow {
  color: $color-text;
  height: 20px;
  transition: transform $transition-time;
  width: 20px;
}

.multi-select__value {
  @include textRegular-14();
  line-height: 22px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-select__dropbox {
  @include dropdown-box();
  left: 0;
  min-width: 100%;
  max-height: 192px;
  overflow: auto;
  padding: 8px 0;
  position: absolute;
  top: 40px;
  z-index: 11;
}

.multi-select__dropbox-list {
  list-style: none;
  margin: 0;
  padding: 0;

  .checkbox__label {
    grid-template-columns: 20px 1fr;
  }
}

.multi-select__dropbox-list-item {
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  transition: background-color $transition-time;

  &:hover {
    background-color: $color-black-5;
  }

  .checkbox__label {
    padding: 12px 14px;
  }
}

.multi-select_open .multi-select__arrow {
  transform: rotate(180deg);
}

.multi-select__no-data {
  @include textRegular-14();
  line-height: 20px;
  padding: 4px 14px;
}
