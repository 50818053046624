@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.map-modal {
  background-color: $color-theme-light;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  cursor: pointer;
  padding: 2px 8px;
}

.map-modal__text {
  @include textSemiBold-12-black-5();
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  letter-spacing: 0.4px;
  line-height: 18px;
  margin: 0;
}

.map-modal__text-part {
  min-width: 25px;
}

.map-modal__text-part_orders {
  text-align: right;
}
