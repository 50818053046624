@import 'src/scss/variables.scss';

.farewells-filters {
  align-items: center;
  display: grid;
  gap: 22px;
  grid-auto-flow: column;
  justify-content: start;
}

.farewells-filters__select {
  width: 210px;

  .multi-select__dropbox {
    width: 360px;
  }
}
