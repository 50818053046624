@mixin button($button-size: 40px, $svg-size: 20px) {
  align-items: center;
  display: grid;
  height: $button-size;
  justify-content: center;
  width: $button-size;

  svg {
    height: $svg-size;
    width: $svg-size;
  }
}
