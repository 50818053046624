@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.order-table-row__cell {
  padding: 12px 20px 12px 0;
}

.order-table-row__cell_status {
  padding-left: 4px;
  padding-right: 0;
}

.order-table-row__cell_date {
  padding-right: 0;
}

.order-table-row__label {
  @include textSemiBold-12();
  border-radius: 4px;
  line-height: 16px;
  padding: 1px 11px;
}

.order-table-row__label_order {
  background: $color-info-alfa;
  color: $color-text-info;
}

.order-table-row__label_pre-order {
  background: $color-warning-alfa;
  color: $color-text-warning;
  padding-left: 13px;
  padding-right: 13px;
}

.order-table-row__label_canceled {
  background: $color-gray-alfa;
  color: $color-text-light;
  padding-left: 9px;
  padding-right: 9px;
}
