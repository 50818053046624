@import 'src/scss/mixins/text';

.popup {
  align-content: start;
  background: $color-black-0;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: grid;
  gap: 21px;
  left: 50%;
  padding: 20px 20px 12px;
  position: fixed;
  top: -100%;
  transform: translate(-50%, -50%);
  transition: top ease-in-out $transition-time * 2;
  z-index: 100;
}

.popup_visible {
  top: 50%;
}

.popup_close-day {
  min-width: 334px;

  .popup__item {
    @include textRegular-14();
    line-height: 20px;
  }
}

.popup_select-workers {
  gap: 10px;
  grid-template-rows: max-content 1fr max-content;
  max-height: 452px;
  min-width: 324px;
  padding-left: 0;
  padding-right: 0;

  .popup__title-wrapper {
    border-bottom: none;
    display: grid;
    gap: 7px;
    padding: 0 0 22px;
    position: relative;

    &:after {
      background-color: $color-black-10;
      bottom: 0;
      content: '';
      height: 1px;
      left: 20px;
      position: absolute;
      right: 20px;
    }
  }

  .popup__items-wrapper {
    box-sizing: border-box;
    gap: 20px;
    overflow: auto;
    padding: 11px 20px 11px 22px;
  }

  .popup__actions {
    padding: 0 20px;
  }
}

.popup__items-wrapper {
  display: grid;
  gap: 12px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.popup__item {
  margin: 0;
}

.popup__item_bold {
  font-weight: $font-weight-semibold;
}

.popup__big-item {
  @include textSemiBold-16();
  line-height: 20px;
}

.popup__title-wrapper {
  border-bottom: 1px solid $color-black-10;
  padding: 0 0 24px;
}

.popup__title {
  @include textSemiBold-20();
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.popup__title-description {
  @include textRegular-12();
  line-height: 16px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.popup__actions {
  display: grid;
  gap: 8px;
}

.popup__cancel-button {
  color: $color-text-ghost;
}
