@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.tooltip {
  @include textSemiBold-12-black-2();
  background-color: $color-black-75;
  border-radius: 8px;
  box-sizing: border-box;
  line-height: 16px;
  padding: 4px 8px;
  position: fixed;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  z-index: 10000;
}
