@import 'src/scss/variables';

.navbar {
  background-color: $color-theme-accent;
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  grid-area: navbar;
  grid-template-rows: max-content 1fr max-content;
  justify-items: center;
  height: 100%;
  margin: 0;
  padding: 20px 0 16px;
  width: 48px;
}

.navbar__menu {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.navbar__menu_bottom {
  padding-top: 17px;
  position: relative;

  &::before {
    background-color: $color-black-2;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 32px;
  }
}

.navbar__item {
  margin: 0;
  width: 100%;
}

.navbar__item-link,
.navbar__item-button {
  align-items: center;
  background: none;
  border: 0;
  color: $color-black-2;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  transition: background-color $transition-time;
  width: 100%;

  &:hover,
  &:focus {
    background-color: $color-theme-light;
  }
}

.navbar__item-link_active {
  background-color: $color-theme-light;
  cursor: auto;
}

.navbar__logo {
  background-color: $color-black-0;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 32px;
  padding: 3px;
  width: 32px;
}
