@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.top-panel {
  align-items: center;
  border-bottom: 1px solid $color-border-gray;
  box-sizing: border-box;
  display: grid;
  gap: 26px;
  grid-auto-flow: column;
  grid-area: top-panel;
  justify-content: space-between;
  padding: 8px 32px;
}

.top-panel__left-wrapper {
  align-items: center;
  display: grid;
  gap: 26px;
  grid-auto-flow: column;
  justify-content: start;
}

.top-panel__heading {
  min-width: 170px;
}

.top-panel__title {
  @include textSemiBold-16();
  line-height: 20px;
  margin: 0;
}

.top-panel__subtitle {
  @include textRegular-12();
  line-height: 16px;
  margin: 0;
}

.top-panel__data-filters {
  align-items: center;
  display: grid;
  gap: 22px;
  grid-auto-flow: column;
  justify-self: end;
}
