// FONT FAMILY
$font-main: 'proxima-nova', 'Arial', sans-serif;

// FONT SIZES
$font-size-tiny: 10px;
$font-size-small: 12px;
$font-size-regular: 14px;
$font-size-medium: 16px;
$font-size-huge: 20px;

// FONT WEIGHTS
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// THEME COLORS
$color-theme-accent: #1f3044;
$color-theme-light: #405c7d;
$color-variant-dark: #fb9039;
$color-variant-normal: #fca661;
$color-variant-light: #fdbc88;
$color-variant-extra-light: rgba(251, 144, 57, 0.12);

// COLORS
$color-red: #ec6d6a;
$color-red-light: #fca6a7;
$color-gray-alfa: #f6f6f6;
$color-gray-beta: #e8e8e8;
$color-info-alfa: rgba(79, 126, 218, 0.08);
$color-warning-alfa: rgba(213, 139, 34, 0.08);
$color-error-alfa: rgba(236, 109, 106, 0.08);

// GRAY PALETTE
$color-black: #000000;
$color-black-75: #3b3b3b;
$color-black-50: #808080;
$color-black-20: #cdcdcd;
$color-black-10: #e5e5e5;
$color-black-5: #f7f7f7;
$color-black-2: #fafafa;
$color-black-0: #ffffff;

// TEXT COLORS
$color-text: $color-black-75;
$color-text-disabled: #d9d9d9;
$color-text-contrast: #f7f7f7;
$color-text-caption: #666666;
$color-text-light: #b9b9b9;
$color-text-ghost: #646c79;
$color-text-info: #4f7eda;
$color-text-warning: #d58b22;
$color-text-red: $color-red;
$color-text-white: $color-black-0;
$color-text-black: $color-black;
$color-text-black-50: $color-black-50;

$color-button-hover: #446c9b;

// BORDER COLORS
$color-border-basic: #b0b0b0;
$color-border-gray: #e5e5e5;
$color-border-dark: #6f6f6f;

// COMPONENTS COLORS
$color-input-focus: rgba(59, 59, 59, 0.1);
$color-input-outline: #35cdfd;

// ALERT COLORS
$color-alert-error-background: #fdf6f6;
$color-alert-info: #3179bc;
$color-alert-info-background: #f5f8fc;
$color-alert-warning: #67b378;
$color-alert-warning-background: #f3f9f4;

// COMPONENTS SIZES
$filter-panel-width: 250px;
$header-height: 64px;
$navbar-width: 48px;

// TRANSITION TIME
$transition-time: 0.15s;
$transition-time-table: 0.4s;
$transition-time-background-disappearance: 6s;
$transition-time-icon-appearance: 0.3s;
$transition-time-icon-disappearance: 1s;
$transition-time-list-documents: 0.2s;

$transition-timing-function: cubic-bezier(0.7, -0.4, 0.4, 1.4);
