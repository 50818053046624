@import 'src/scss/variables';

.list-documents {
  background-color: $color-black-0;
  border-top: 1px solid $color-border-gray;
  grid-row: 2 / 3;
  position: relative;
}

.list-documents__toggle-button-wrapper {
  height: 32px;
  overflow: hidden;
  position: absolute;
  right: 12px;
  top: -33px;
  width: 40px;
}

.list-documents__toggle-button {
  background-color: $color-black-0;
  border-radius: 4px 4px 0px 0px;
  bottom: 0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  color: $color-black-75;
  position: absolute;
  right: 8px;
}

.list-documents__toggle-button_up svg {
  transform: rotate(180deg);
}

.list-documents__divider {
  background-color: $color-black-10;
  height: 32px;
  width: 1px;
}

.list-documents__divider_full-height {
  height: 100%;
}

.list-documents__wrapper {
  backface-visibility: hidden;
  overflow: hidden;
  transform-style: preserve-3d;
  transition: height $transition-timing-function $transition-time-list-documents;
}
