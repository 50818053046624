@import 'src/scss/variables';
@import 'src/scss/mixins/text';
@import 'src/scss/mixins/dropdown';

.date-picker-modal {
  box-sizing: border-box;
  cursor: auto;
  left: 0;
  position: absolute;
  top: 52px;
  width: 300px;
  z-index: 10;
}

.date-picker-modal_position_right {
  left: auto;
  right: 0;
}

.date-picker-modal__wrapper {
  @include dropdown-box();
  display: grid;
  gap: 24px;
  padding: 20px;
}
