@import 'src/scss/variables';
@import 'src/scss/mixins/button';
@import 'src/scss/mixins/text';

.button {
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  position: relative;
}

.button_filled {
  @include textSemiBold-14-contrast();
  background-color: $color-variant-dark;
  border: 0;
  border-radius: 4px;
  letter-spacing: 0.03em;
  line-height: 20px;
  outline: none;
  padding: 10px 12px;
  transition: all ease-in-out $transition-time;

  &:hover:not(:disabled) {
    background-color: $color-variant-normal;
  }

  &:focus-visible:not(:disabled) {
    background-color: $color-variant-light;
  }
}

.button_borderless {
  @include textSemiBold-14();
  background: $color-black-0;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  letter-spacing: 0.03em;
  line-height: 20px;
  outline: none;
  padding: 10px 12px;
  transition: border-color $transition-time ease-in-out;

  &:hover:not(:disabled) {
    border-color: $color-variant-light;
  }

  &:focus-visible:not(:disabled) {
    border-color: $color-variant-normal;
  }
}

.button_disabled {
  cursor: default;
}

.button_borderless.button_disabled,
.button_filled.button_disabled {
  background: $color-gray-alfa;
  border-color: $color-gray-alfa;
  color: $color-text-disabled;
}

.button svg {
  left: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.button_icon {
  background: none;
  border: none;
  height: 20px;
  margin: 0;
  padding: 0;
  width: 20px;

  svg {
    position: static;
    transform: translate(0);
  }
}

.button_icon.button_lg {
  @include button(32px);
}

.button_icon.button_md {
  @include button();
}

.button_icon.button_sm {
  @include button(24px, 16px);
}

.button_icon.button_xs {
  @include button(20px, 16px);
}

.button_link {
  @include textRegular-14();
  background: none;
  border: none;
  line-height: 20px;
  margin: -4px;
  padding: 4px;
  text-transform: uppercase;
}

.button_link.button_sm {
  @include textRegular-12();
  background: none;
  border: none;
  line-height: 14px;
  padding: 0;
  text-transform: uppercase;
}

.button_link.button_sm.button_disabled {
  color: $color-black-10;
}

.button_ghost {
  @extend .button_filled;
  background-color: $color-black-2;
  color: $color-text-ghost;

  &:hover:not(:disabled) {
    background-color: $color-gray-alfa;
  }

  &:focus-visible:not(:disabled) {
    background-color: $color-gray-beta;
  }
}
