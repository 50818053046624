@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.arrow-button {
  align-items: center;
  background: none;
  border: none;
  color: $color-text;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;

  &:focus-visible:not(:disabled) {
    box-shadow: 0 0 0 1px $color-black-0, 0 0 0 3px $color-input-outline;
  }
}

.arrow-button_disabled {
  color: $color-text-disabled;
  cursor: not-allowed;
}

.arrow-button__icon {
  height: 20px;
  transition: all $transition-time ease-in;
  width: 20px;
}

.arrow-button_left {
  .arrow-button__icon {
    transform: rotate(90deg);
  }
}

.arrow-button_right {
  .arrow-button__icon {
    transform: rotate(-90deg);
  }
}

.arrow-button_up {
  .arrow-button__icon {
    transform: rotate(-180deg);
  }
}

.arrow-button_down {
  .arrow-button__icon {
    transform: rotate(0);
  }
}
