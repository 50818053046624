@import 'src/scss/variables.scss';
@import 'src/scss/mixins/text';

.date-picker {
  align-items: center;
  background: none;
  border: 1px solid $color-border-gray;
  border-radius: 4px;
  box-sizing: border-box;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 3px 8px;
  position: relative;
  width: 236px;
}

.date-picker__toggle {
  background: none;
  border: none;
  cursor: pointer;
  margin: -2px;
  outline-color: $color-input-outline;
  padding: 2px 4px;
}

.date-picker__toggle-wrapper {
  display: grid;
  gap: 4px;
}

.date-picker__toggle-date {
  @include textSemiBold-16();
  line-height: 20px;
}

.date-picker__toggle-day {
  @include textSemiBold-12-light();
  line-height: 16px;
  text-transform: capitalize;
}

.date-picker__arrow-button {
  border-radius: 50%;
  height: 32px;
  width: 32px;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background-color: $color-gray-alfa;
  }

  &:active {
    background-color: $color-gray-beta;
  }
}
