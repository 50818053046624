@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.filter-panel {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  grid-row: 1 / -1;
  padding: 20px;
  position: relative;

  &::after {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 100%);
    content: '';
    height: 100%;
    position: absolute;
    right: -8px;
    top: 0;
    width: 8px;
    z-index: 100;
  }
}

.filter-panel__section {
  border-bottom: 1px solid $color-black-10;
  display: grid;
  gap: 16px;
  padding: 0 0 16px;
}

.filter-panel__section_crematories {
  gap: 23px;
  padding-bottom: 21px;
}

.filter-panel__section_date {
  padding-bottom: 20px;
  padding-top: 8px;
}

.filter-panel__section_checkboxes {
  gap: 19px;
  padding-bottom: 18px;
}

.filter-panel__section-title {
  @include textSemiBold-16();
  line-height: 20px;
  margin: 0;
  padding: 0;
}
