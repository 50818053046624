@import 'src/scss/variables.scss';

.loader {
  box-sizing: border-box;
  height: 80px;
  width: 80px;
}

.loader_position_overlay {
  align-items: center;
  background-color: rgba($color: $color-black-5, $alpha: 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 111;
}
