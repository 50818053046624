@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.radiobox {
  position: relative;
  z-index: 2;
}

.radiobox__input {
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.radiobox__label {
  @include textRegular-14();
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 10px;
  grid-template-columns: 20px max-content;
  line-height: 24px;
  white-space: nowrap;
}

.radiobox__icon {
  background-color: $color-black-0;
  border: 1px solid $color-border-basic;
  border-radius: 50%;
  box-sizing: border-box;
  height: 20px;
  position: relative;
  transition: border-color $transition-time ease-in-out;
  width: 20px;

  &::before {
    background-color: transparent;
    border-radius: 50%;
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color $transition-time ease-in-out;
    z-index: -1;
  }
}

.radiobox:hover:not(.radiobox_disabled),
.radiobox:focus-within {
  .radiobox__icon {
    border-color: $color-border-dark;
  }

  .radiobox__icon::before {
    background-color: $color-black-5;
  }
}

.radiobox__input:focus-visible + .radiobox__icon::before {
  background-color: rgba($color: $color-black-75, $alpha: 0.04);
}

.radiobox__input + label .radiobox__icon::after {
  background-color: $color-black-75;
  border-radius: 50%;
  content: '';
  display: block;
  height: 8px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all ease-in-out $transition-time;
  width: 8px;
  z-index: 2;
}

.radiobox__input:checked + label .radiobox__icon::after {
  opacity: 1;
}

.radiobox__input:disabled {
  & + label {
    color: $color-text-disabled;
    cursor: default;
  }

  & + label .radiobox__icon {
    background-color: $color-gray-alfa;
    border-color: $color-gray-alfa;
  }

  &:checked + label .radiobox__icon::after {
    background-color: $color-text-disabled;
  }
}
