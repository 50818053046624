@import 'src/scss/variables';

@mixin textRegular-10 {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-tiny;
  font-weight: $font-weight-regular;
}

@mixin textRegular-10-caption {
  @include textRegular-10();
  color: $color-text-caption;
}

@mixin textRegular-12 {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
}

@mixin textRegular-12-caption {
  @include textRegular-12();
  color: $color-text-caption;
}

@mixin textRegular-12-disabled {
  @include textRegular-12();
  color: $color-text-disabled;
}

@mixin textRegular-12-error {
  @include textRegular-12();
  color: $color-text-red;
}

@mixin textRegular-12-light {
  @include textRegular-12();
  color: $color-text-light;
}

@mixin textSemiBold-12 {
  @include textRegular-12();
  font-weight: $font-weight-semibold;
}

@mixin textSemiBold-12-light {
  @include textSemiBold-12();
  color: $color-text-light;
}

@mixin textSemiBold-12-contrast {
  @include textSemiBold-12();
  color: $color-text-contrast;
}

@mixin textSemiBold-12-black-2 {
  @include textSemiBold-12();
  color: $color-black-2;
}

@mixin textSemiBold-12-black-5 {
  @include textSemiBold-12();
  color: $color-black-5;
}

@mixin textBold-12 {
  @include textRegular-12();
  font-weight: $font-weight-bold;
}

@mixin textBold-12-error {
  @include textBold-12();
  color: $color-text-red;
}

@mixin textBold-12-red {
  @include textBold-12();
  color: $color-red;
}

@mixin textRegular-14 {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-regular;
  font-weight: $font-weight-regular;
}

@mixin textRegular-14-black {
  @include textRegular-14();
  color: $color-text-black;
}

@mixin textRegular-14-black-50 {
  @include textRegular-14();
  color: $color-text-black-50;
}

@mixin textRegular-14-caption {
  @include textRegular-14();
  color: $color-text-caption;
}

@mixin textRegular-14-disabled {
  @include textRegular-14();
  color: $color-text-disabled;
}

@mixin textRegular-14-error {
  @include textRegular-14();
  color: $color-text-red;
}

@mixin textRegular-14-light {
  @include textRegular-14();
  color: $color-text-light;
}

@mixin textSemiBold-14 {
  @include textRegular-14();
  font-weight: $font-weight-semibold;
}

@mixin textSemiBold-14-black-50 {
  @include textSemiBold-14();
  color: $color-text-black-50;
}

@mixin textSemiBold-14-contrast {
  @include textSemiBold-14();
  color: $color-text-contrast;
}

@mixin textSemiBold-14-disabled {
  @include textSemiBold-14();
  color: $color-text-disabled;
}

@mixin textSemiBold-14-error {
  @include textSemiBold-14();
  color: $color-text-red;
}

@mixin textSemiBold-14-ghost {
  @include textSemiBold-14();
  color: $color-text-ghost;
}

@mixin textSemiBold-14-light {
  @include textSemiBold-14();
  color: $color-text-light;
}

@mixin textSemiBold-14-white {
  @include textSemiBold-14();
  color: $color-text-white;
}

@mixin textRegular-16 {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-medium;
  font-weight: $font-weight-regular;
}

@mixin textRegular-16-disabled {
  @include textRegular-16();
  color: $color-text-disabled;
}

@mixin textSemiBold-16 {
  @include textRegular-16();
  font-weight: $font-weight-semibold;
}

@mixin textSemiBold-16-black-50 {
  @include textSemiBold-16();
  color: $color-text-black-50;
}

@mixin textSemiBold-16-light {
  @include textSemiBold-16();
  color: $color-text-light;
}

@mixin textSemiBold-16-error {
  @include textSemiBold-16();
  color: $color-text-red;
}

@mixin textRegular-20 {
  color: $color-text;
  font-family: $font-main;
  font-size: $font-size-huge;
  font-weight: $font-weight-regular;
}

@mixin textSemiBold-20 {
  @include textRegular-20();
  font-weight: $font-weight-semibold;
}

@mixin textSemiBold-20-disabled {
  @include textSemiBold-20();
  color: $color-text-disabled;
}
