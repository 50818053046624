@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.sorting-table-row {
  align-items: center;
  background-color: $color-black-0;
  border: 1px solid $color-black-10;
  border-radius: 4px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 115px 238px 200px minmax(168px, 1fr) max-content 28px;
  padding: 12px 24px;
}

.sorting-table-row__cell {
  padding: 0;
}

.sorting-table-row__deceased-wrapper {
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-columns: 20px auto;
  padding-right: 28px;
}

.sorting-table-row__icon {
  color: $color-black-10;
  height: 20px;
  width: 20px;
}

.sorting-table-row__icon_scanned {
  color: $color-variant-dark;
}

.sorting-table-row__warning-icon {
  color: $color-red;
}

.sorting-table-row__deceased {
  display: grid;
  gap: 4px;
}

.sorting-table-row__deceased-name {
  @include textRegular-14();
  line-height: 20px;
}

.sorting-table-row__deceased-dod {
  @include textRegular-12-caption();
  line-height: 16px;
}

.sorting-table-row__comments {
  @include textRegular-14();
  display: inline-block;
  line-height: 20px;
  padding-right: 28px;
}

.sorting-table-row__cremations_radiobox {
  display: grid;
  gap: 24px;
  grid-auto-columns: 36px;
  grid-auto-flow: column;
  justify-items: center;
  padding-right: 22px;
}

.sorting-table-row__status {
  @include textBold-12-error();
  background: $color-error-alfa;
  border-radius: 4px;
  text-align: center;
  width: 28px;
}

.sorting-table-row__status_disabled {
  background: rgba(190, 190, 190, 0.08);
  color: $color-text-disabled;
}

.sorting-table-row_marked {
  background: $color-black-2;
}

.sorting-table-row_warning {
  background: $color-error-alfa;

  .table__text,
  .table__small-text {
    color: $color-red;
    font-weight: $font-weight-semibold;
  }
}

.sorting-table-row__date {
  display: grid;
  gap: 7px;
  grid-auto-flow: column;
  justify-content: start;

  .tooltip-trigger {
    align-self: start;
  }
}
