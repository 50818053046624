@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.list-documents-info {
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  justify-content: start;
  padding: 17px 19px;
}

.list-documents-info__item {
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(3, max-content) 1fr;
  padding-bottom: 6px;
  padding-top: 6px;
}

.list-documents-info__text {
  @include textRegular-14();
  line-height: 20px;
}

.list-documents-info__text_grey {
  color: $color-text-caption;
}

.list-documents-info__text_red {
  color: $color-red;
}

.list-documents-info__text_semibold {
  font-weight: $font-weight-semibold;
}

.list-documents-info__workers {
  overflow: hidden;
  overflow-wrap: break-word;
  padding-right: 3px;
}
