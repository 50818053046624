@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.order-table_table_expanded {
  .order-table__body {
    height: auto;
  }
}

.order-table_table_opened {
  .order-table-head {
    border-bottom: 1px solid $color-black-10;
  }

  .order-table-head__cell {
    background-color: $color-black-2;
  }

  .order-table__body {
    height: auto;
  }

  .order-table-head__icon-wrapper {
    background-color: $color-gray-alfa;
  }

  .order-table-head__icon {
    color: $color-text;
    transform: rotate(180deg);
  }
}

.order-table__wrapper {
  backface-visibility: hidden;
  background: $color-black-0;
  border: 1px solid $color-gray-beta;
  border-radius: 4px;
  grid-column: 1 / -1;
  overflow: hidden;
  padding: 0;
  transform-style: preserve-3d;
  transition: height $transition-timing-function $transition-time-table,
    border-color $transition-time-table;
}

.order-table__wrapper_expanded {
  border-color: $color-variant-light;
}

.order-table__body {
  height: 0;
  overflow: hidden;
}

.order-table__row {
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 92px minmax(278px, 19vw) minmax(188px, 13vw) 1fr 43px 92px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;

  &:after {
    border-bottom: 1px solid $color-black-10;
    bottom: 0;
    content: '';
    height: 0;
    left: 24px;
    position: absolute;
    right: 24px;
  }
}

.order-table__row_hover-transition {
  transition: background-color $transition-time;

  &:hover {
    background-color: $color-black-2;
  }
}

.order-table__row_new {
  backface-visibility: hidden;
  background-color: $color-variant-extra-light;
  height: 0;
  overflow: hidden;
  transform-style: preserve-3d;
  transition: height $transition-timing-function $transition-time-table;
}

.order-table__row_background-disappearance {
  background-color: $color-black-0;
  height: auto;
  transition: background-color $transition-time-background-disappearance;
}

.order-table__row_canceled {
  .table__text {
    color: $color-text-light;
  }

  .table__small-text {
    color: $color-text-disabled;
  }

  .order-table-row__cell_status {
    padding-left: 0;
  }
}

.order-table__row_last:after {
  display: none;
}
