@import 'src/scss/variables.scss';
@import 'src/scss/mixins/text';

.date-stat {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
}

.date-stat__values {
  align-content: start;
  display: grid;
  gap: 4px;
  justify-items: end;
}

.date-stat__values-item {
  @include textRegular-12();
  line-height: 16px;
  margin: 0;
}

.date-stat__values-item-count {
  font-weight: $font-weight-semibold;
}
