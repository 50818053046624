@import 'src/scss/variables';

.control-room-page {
  background: $color-black-5;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 20px 0 0;
}

.control-room-page_empty {
  align-content: start;
  display: grid;
  grid-template-rows: max-content 1fr;
}

.control-room-page__empty-data {
  align-self: center;
  justify-self: center;
  transform: translateY(-68px);
}

.control-room-page__icon {
  color: $color-black-0;
}

.control-room-page__table-wrapper {
  height: 100%;
  overflow: auto;
  padding: 0 20px 0;
}
