@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.empty-data {
  align-content: start;
  display: grid;
  gap: 19px;
  justify-items: center;
  width: 210px;
}

.empty-data__text-wrapper {
  display: grid;
  gap: 16px;
  justify-items: center;
}

.empty-data__title {
  @include textSemiBold-20-disabled();
  line-height: 20px;
  margin: 0;
}

.empty-data__text {
  @include textRegular-16-disabled();
  line-height: 20px;
  margin: 0;
  text-align: center;
}

.empty-data__icon {
  color: $color-text-disabled;
}
