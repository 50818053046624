@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.label {
  @include textRegular-10-caption();
  background: $color-black-0;
  left: 7px;
  padding: 0 4px;
  position: absolute;
  top: -9px;
  transition: top $transition-time, padding $transition-time, font-size $transition-time,
    left $transition-time;
}

.label_select {
  top: -8px;
}

.label_checkbox {
  @include textRegular-14();
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  justify-content: start;
  padding: 0;
  position: static;
  text-transform: none;
  white-space: nowrap;
}

.label_placeholder {
  @include textRegular-14-light();
  left: 12px;
  padding: 0;
  pointer-events: none;
  top: 6px;
}

.label_invalid {
  color: $color-red;
}

.label_disabled {
  background: transparent;
  color: $color-black-20;
}
