@import 'src/scss/mixins/text';

.text-error {
  @include textRegular-12-error();
  bottom: -20px;
  display: block;
  line-height: 160%;
  position: absolute;
  right: 0;
  text-align: right;
}
