@import 'src/scss/variables';

.sorting-table {
  background: $color-black-2;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 20px 0 20px;
}

.sorting-table_empty {
  align-content: start;
  display: grid;
  grid-template-rows: max-content 1fr;
}

.sorting-table__table-wrapper {
  height: 100%;
  overflow: auto;
  padding: 0 20px 20px;
}

.sorting-table__table {
  gap: 12px;
}

.sorting-table__table .table__header {
  background: $color-variant-light;
  border-radius: 4px;
}

.sorting-table__table_disabled .table__header {
  background: $color-text-disabled;
}

.sorting-table__table .table__row {
  align-items: center;
  display: grid;
  grid-template-columns: 115px 238px 200px minmax(168px, 1fr) max-content 28px;
  padding: 6px 24px;
}

.sorting-table__table {
  .table__header-cell {
    background: transparent;
    padding: 0;
  }

  .table__header-cell_cremated {
    padding-left: 37px;
  }
}

.sorting-table__table .table__header-cell_last {
  height: 20px;
  text-align: center;
}

.sorting-table__cremations_abbr {
  display: grid;
  gap: 24px;
  grid-auto-columns: 36px;
  grid-auto-flow: column;
  justify-items: center;
  padding-right: 28px;
}

@media (max-width: 1400px) {
  .sorting-table__table-wrapper {
    padding: 0 10px 10px;
  }
}
