@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.alert {
  border-radius: 0 2px 2px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: grid;
  gap: 8px 20px;
  grid-template-areas:
    'title close'
    'desc close';
  grid-template-columns: 1fr 16px;
  grid-template-rows: max-content max-content;
  padding: 16px 16px 16px 19px;
  position: relative;
  width: 400px;
}

.alert::before {
  border-radius: 8px 0 0 8px;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}

.alert__title {
  @include textSemiBold-16();
  grid-area: title;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert__desc {
  @include textRegular-14();
  grid-area: desc;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert__type-icon {
  grid-area: icon;
}

.alert__type-icon_error {
  color: $color-red;
}

.alert__type-icon_info {
  color: $color-alert-info;
}

.alert__type-icon_warning {
  color: $color-alert-warning;
}

.alert__close-icon {
  color: $color-black-75;
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.alert_icon {
  align-items: center;
  grid-template-areas:
    'icon title close'
    'icon desc close';
  grid-template-columns: 20px 1fr 16px;
}

.alert_error {
  background: $color-alert-error-background;
}

.alert_error::before {
  background: $color-red;
}

.alert_error .alert__title {
  color: $color-red;
}

.alert_warning {
  background: $color-alert-warning-background;
}

.alert_warning::before {
  background: $color-alert-warning;
}

.alert_warning .alert__title {
  color: $color-alert-warning;
}

.alert_info {
  background: $color-alert-info-background;
}

.alert_info::before {
  background: $color-alert-info;
}

.alert_info .alert__title {
  color: $color-alert-info;
}
