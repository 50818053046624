@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.checkbox {
  position: relative;
  z-index: 2;
}

.checkbox__input {
  height: 0;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.checkbox__label {
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 10px;
  grid-template-columns: 20px max-content;
}

.checkbox__label-text {
  @include textRegular-14();
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox__icon {
  background: $color-black-0;
  border: 1px solid $color-border-basic;
  border-radius: 4px;
  box-sizing: border-box;
  height: 20px;
  transition: border-color $transition-time ease-in-out,
    background-color $transition-time ease-in-out;
  position: relative;
  width: 20px;
}

.checkbox__icon::before {
  background-color: transparent;
  border-radius: 50%;
  content: '';
  display: block;
  height: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  z-index: -1;
}

.checkbox:hover:not(.checkbox_disabled),
.checkbox:focus-within:not(.checkbox_disabled) {
  .checkbox__icon {
    border-color: $color-border-dark;
  }

  .checkbox__icon::before {
    background-color: $color-black-5;
  }
}

.checkbox__input:focus-visible .checkbox__icon::before {
  background-color: rgba($color: $color-black-75, $alpha: 0.04);
}

.checkbox__input + label .checkbox__icon::after {
  border: 1px solid $color-black-75;
  border-left: 0;
  border-top: 0;
  content: '';
  display: block;
  height: 9px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 2px;
  transform: rotate(45deg);
  transition: all ease-in-out $transition-time;
  width: 5px;
  z-index: 2;
}

.checkbox__input:checked + label .checkbox__icon::after {
  opacity: 1;
}

.checkbox__input:disabled {
  & + label {
    color: $color-text-disabled;
    cursor: default;
  }

  & + label .checkbox__icon {
    background-color: $color-gray-alfa;
    border-color: $color-gray-alfa;
  }

  &:checked + label .checkbox__icon::after {
    border-color: $color-text-disabled;
  }
}
